<template>
  <v-data-table
    :headers="headers"
    :items="reports"
    :search="search"
    sort-by="calories"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <!-- <v-toolbar-title> -->
        <v-text-field
          v-model="search"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
        <!-- </v-toolbar-title> -->
        <!-- <v-divider class="mx-4" inset vertical></v-divider> -->
        <v-spacer></v-spacer>
      </v-toolbar>
    </template>
    <template v-slot:item.index="{ index }">
      {{ index + 1 }}
    </template>
    <template v-slot:item.date_time="{ item }">
      <span v-if="item.emp_date && item.emp_time">
        {{ item.emp_date }} {{ item.emp_time }}
      </span>
      <span v-if="item.mem_date && item.mem_time">
        {{ item.mem_date }} {{ item.mem_time }}
      </span>
    </template>
    <template v-slot:item.ref="{ item }">
      {{ (item.order_inv || item.claim_no || item.mem_package_no) || item.note || '-' }}
    </template>
    <template v-slot:item.credit="{ item }">
      <span v-if="item.credit_product_amt && item.credit_product_amt > 0"
            class="credit-push">+{{ item.credit_product_amt || 0 }}
      </span>
    </template>
    <template v-slot:item.cut_credit="{ item }">
      <span v-if="item.credit_product_amt && item.credit_product_amt <  0"
            class="credit-remove">{{item.credit_product_amt || 0}}
      </span>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        {{ icons.mdiPencil }}
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
      >
        {{ icons.mdiDelete }}
      </v-icon>
    </template>
    <template v-slot:no-data>
      <!-- <v-btn
        color="primary"
        @click="initialize"
      >
        Reset
      </v-btn> -->
    </template>
  </v-data-table>
</template>

<script>
import {mdiPencil, mdiDelete} from '@mdi/js'
// import config from '../../../config'
import instance_report from '@/services/report'
import instance_user from '@/services/userManage'

export default {
  setup() {
    return {
      icons: {
        mdiPencil,
        mdiDelete,
      },
    }
  },
  data: () => ({
    reports: [],
    dialog: false,
    dialogDelete: false,
    search: '',
    headers: [
      {
        text: 'ลำดับ',
        value: 'index', sortable: false
      },
      {text: 'วัน-เวลา', value: 'date_time', sortable: false},
      {text: 'เลขอ้างอิง', value: 'ref', sortable: false},
      {text: 'แพ็กเกจ', value: 'product_type_name', sortable: false},
      {text: 'เครดิต', value: 'credit', sortable: false},
      {text: 'ตัดเครดิต', value: 'cut_credit', sortable: false},
      {text: 'คงเหลือ', value: 'remain', sortable: false},
    ],
    desserts: [],
    editedIndex: -1,
    editedItem: {
      employee_type_name: '',
      employee_type_comment: ''
    },
    defaultItem: {
      employee_type_name: '',
      employee_type_comment: ''
    },
  }),


  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'เพิ่ม กลุ่มพนักงาน' : 'แก้ไข กลุ่มพนักงาน'
    },
  },

  watch: {
    // dialog(val) {
    //   val || this.close()
    // },
    // dialogDelete(val) {
    //   val || this.closeDelete()
    // },
  },

  created() {
    // this.initialize()
  },
  mounted() {
    this.getReport()
  },

  methods: {

    editItem(item) {
      this.editedIndex = this.reports.indexOf(item)
      this.editedItem = {...item}
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.reports.indexOf(item)
      this.editedItem = {...item}
      this.dialogDelete = true
    },
    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = {...this.defaultItem}
        this.editedIndex = -1
      })
    },

    getReport() {
      this.$store.state.isLoading = true
      var data = {
        search: '',
        date: '',
        status: '',
      }
      instance_user.getReportPackage(data).then(res => {
        this.reports = res.data.data;
        this.$store.state.isLoading = false
      }).catch(err => {
        this.$store.state.isLoading = false

      });
    },
  },
}
</script>

<style scoped>
hr {
  background-color: #e5e5e5;
  height: 1px;
  border: none;
}
</style>
`
