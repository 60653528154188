var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.reports,"search":_vm.search,"sort-by":"calories"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-text-field',{attrs:{"label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer')],1)]},proxy:true},{key:"item.index",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:"item.date_time",fn:function(ref){
var item = ref.item;
return [(item.emp_date && item.emp_time)?_c('span',[_vm._v(" "+_vm._s(item.emp_date)+" "+_vm._s(item.emp_time)+" ")]):_vm._e(),(item.mem_date && item.mem_time)?_c('span',[_vm._v(" "+_vm._s(item.mem_date)+" "+_vm._s(item.mem_time)+" ")]):_vm._e()]}},{key:"item.ref",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((item.order_inv || item.claim_no || item.mem_package_no) || item.note || '-')+" ")]}},{key:"item.credit",fn:function(ref){
var item = ref.item;
return [(item.credit_product_amt && item.credit_product_amt > 0)?_c('span',{staticClass:"credit-push"},[_vm._v("+"+_vm._s(item.credit_product_amt || 0)+" ")]):_vm._e()]}},{key:"item.cut_credit",fn:function(ref){
var item = ref.item;
return [(item.credit_product_amt && item.credit_product_amt <  0)?_c('span',{staticClass:"credit-remove"},[_vm._v(_vm._s(item.credit_product_amt || 0)+" ")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencil)+" ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiDelete)+" ")])]}},{key:"no-data",fn:function(){return undefined},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }